@import url(./darktheme.css);



.wide-view.whats-modal {
    display: flex;
    align-items: flex-start;
    flex-direction: row;

}

.no-mr {
    margin: 0 !important;
}

.no-mr:hover path {
    color: rgb(223, 51, 51);
    fill: rgb(223, 51, 51);
}

.add-employee {
    width: 100%;
    height: 40px;
    color: rgb(0, 150, 136);

}

.add-employee-modal-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: none;
    top: 0px;
    left: 0px;
}

.show-add-modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.add-modal-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: 9999999999;
    background-color: #54656f;
    opacity: 0.3;
}

.add-employee-window {
    max-width: 700px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    z-index: 99999999999;
    min-height: 200px;
    max-height: 300px;
    overflow: auto;
    background-color: white;

}

.add-employee-window .profile__section {
    width: 100%;
    padding: 5px;
}

.remove-employee {
    width: 30px;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50%;
    background-color: rgba(102, 119, 129, 0.153);
    color: #5d5d5d;
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: center;
}

.chat {
    display: none;
    transition: all 0.4s ease;
    z-index: 15;
    max-width: 100%;
    border-radius: 10px;
    position: absolute;
    right: -800px;
    height: calc(100vh - 100px);
}

.vcard-message.message-hold--sent .chat__msg .vcard-option {
    display: none;
    width: auto;
    max-width: 78%;
}

.wide-view .chat,
.wide-view .sidebar {
    position: relative !important;
}

.wide-view .chat .chat__input {
    max-width: 100%;
}

.wide-view .chat {
    right: initial !important;
    left: initial !important;
    width: 100%;
    border-radius: 10px 0px 0px 10px;
}

.wide-view .chat .chat__body {
    border-radius: 10px 0px 0px 10px;
}

.wide-view .chat .chat__body,
.wide-view .chat .chat__body #XD-1 {
    width: 100%;
    max-width: 100%;
}

.hywana .chat {
    animation-name: myAnimation2;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    transition: width 0.5s linear;
}

@keyframes myAnimation2 {
    0% {
        transform: translateX(450px);
        width: 450px !important;
    }

    100% {
        transform: translateX(0px);
        width: calc(100vw - 750px) !important;
    }
}

.hywan .chat {
    animation-name: myAnimation2rev;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    transition: width 0.5s linear;
}

.chat.activeC {
    right: 0px;
    width: 100%;
    display: flex;
    background-color: #f8f9fa;
    transition: width 0.5s linear;
}

@keyframes myAnimation2rev {
    0% {
        transform: translateX(-450px);
        width: calc(100vw - 750px) !important;
    }

    100% {
        transform: translateX(0px);
        width: 450px !important;
    }
}

.chat__body {
    width: 100%;
    transition: all 0.4s ease;
    border-radius: 10px;
    border-right: 1px solid #DADADA;
    display: none;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.activeC .chat__body {
    display: flex;
    animation-name: showUp;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}

.wide-view .chat__bg {
    border-radius: 10px 0px 0px 10px;
}

.chat__bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.05;
    width: 100%;
    z-index: 1;
    background: url(../../../assets/images/bg-chat-light.png) #E4DCD4;
    border-radius: 10px;
}

.chat__header,
.chat__footer,
.chat__date-wrapper,
.chat__msg-group,
.chat__encryption-msg {
    z-index: 10;
}

.chat__header {
    /* Needed for the options btn to stay on top */
    z-index: 19;
}


/* Chat Header Component  */

.chat__avatar-wrapper {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
}

.chat__contact-wrapper {
    flex: 1;
    cursor: pointer;
}

.chat__contact-name,
.chat__contact-desc {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chat__contact-name {
    color: #000000;
    font-size: 1rem;
    max-width: 168px;
    margin-bottom: 2px;
}

.chat__contact-desc {
    color: #00000099;
    font-size: 0.75rem;
}

.chat__actions {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.chat__action {
    margin-left: 25px;
    cursor: pointer;
}

.chat__action:not(.options-btn) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.hash_scan_detail_container {
    position: fixed;
    background-color: #FCFFFA;
    border-radius: 10px;
    padding: 8px;
    width: 388px;
    z-index: 9999999999;
    left: 30px;
    top: 50%;
    min-height: 189px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.hash_scan_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: #47C40E;
    padding-bottom: 17px;
    width: -webkit-fill-available;
}

.hash_scan_loading_pos {
    min-height: 159px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.hash_scan_title svg {
    margin-right: 10px;
}

.hash_scan_details {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    margin-right: 26px;
}

.hash_scan_details_title {
    min-width: 96px;
}

.hash_scan_details_data {
    display: flex;
    flex-direction: column;

}

.hash_scan_details_element {
    height: 16px;
    overflow: hidden;
    max-width: 250px;
    margin-bottom: 3px;
    font-size: 12px;
    color: #404040;
}

.hash_scan_details_element_green {
    height: 16px;
    overflow: hidden;
    max-width: 250px;
    margin-bottom: 3px;
    font-size: 12px;
    color: #4B8C3A;
}

.hash_scan_details_element_red {
    height: 16px;
    overflow: hidden;
    max-width: 250px;
    margin-bottom: 3px;
    font-size: 12px;
    color: #8c3a3a;
}

.hash_scan_details_element a {
    color: #4B66FA;
}

.chat__action-icon {
    color: rgb(145, 145, 145);
}

.chat__action-icon--search {
    width: 30px;
    height: 30px;
}

/* End Chat Header Component  */

/* Chat Content */
* {
    overflow-anchor: auto;
}

.chat__content {
    display: flex;
    flex-direction: column;
    padding: 0px 1% 0px 2%;
    vertical-align: baseline;
    max-height: calc(100% - 139px);
    height: calc(100% - 139px);
    z-index: 9;
    overflow-anchor: none;
    position: relative;
    /* background: #E4DCD4; */
    overflow-y: scroll;
    overflow-x: hidden;
    /* background-image: url("../../../assets/images/chatBack.jpg"); */
}

.chat__date-wrapper {
    text-align: center;
    display: flex;
    height: 27px;
    margin: 10px 0 14px;
    position: absolute;
    top: 6px;
    /* right: 50%; */
    width: 80px;
    left: 50%;

    margin-top: -46px !important;
    z-index: 99999999;
    left: 176px;
    right: 4px;
    margin: 0 auto;
    justify-content: flex-start;
}

.unre {
    position: absolute;
    top: -3px;
    left: 0px;
}

.chat__date-wrapper.scroled,
.chat__date-wrapper.scroledk {
    margin-top: 5px !important;
}

.chat__date {
    background: #E1F2FA;
    display: inline-block;
    color: #000000;
    font-size: 12px;
    margin-right: 41px;
    transform: translateX(-78px);
    padding: 7px 10px;
    width: auto;
    max-width: 80px;
    border-radius: 5px;
    position: fixed;
    /* left: 0px; */
    /* right: 0px; */
    /* margin: 0 auto; */

}

.normal-date {
    background: #E1F2FA;
    display: inline-block;
    color: #000000;
    font-size: 12px;
    max-width: 112px;
    border-radius: 5px;
    margin-right: 0px !important;
    transform: none !important;
    padding: 5px 10px;
    width: auto;
    border-radius: 5px;
    position: static !important;
}

.normal-date-wrapper {
    text-align: center;
    display: flex;
    width: 100% !important;
    height: 27px;
    margin: 10px 0 14px;
    position: relative !important;
    top: initial !important;
    /* right: 50%; */
    width: 80px;
    left: initial !important;
    margin-top: 0px !important;
    z-index: 20 !important;
    justify-content: center;
}

.hide-date {
    transform: translateY(-1000px);
}

.chat__encryption-msg {
    background: #FDF4C5;
    color: #000000;
    font-size: 0.77rem;
    min-width: 376px;
    text-align: center;
    padding: 5px 10px;
    position: relative;
    margin-bottom: 8px;
    border-radius: 5px;
    line-height: 20px;
}

.chat__encryption-icon {
    color: #8C866C;
    margin-right: 5px;
    margin-bottom: -1px;
}

#startup {
    width: 25px;
    height: 20px;
    -moz-user-select: none;
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation-name: showUp;
    animation-name: showUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

#startup2 {
    width: 20px;
    height: 20px;
    -moz-user-select: none;
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation-name: showUp;
    animation-name: showUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

.spinner-container {
    animation: rotate 2s linear infinite;
    -webkit-animation: rotate 2s linear infinite;
}

.spinner-container .path {
    stroke-dasharray: 1, 150;
    /* 1%, 101% circumference */
    stroke-dashoffset: 0;
    stroke: rgba(27, 154, 89, 0.7);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
    -webkit-animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        /* 1%, 101% circumference */
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        /* 70%, 101% circumference */
        stroke-dashoffset: -35;
        /* 25% circumference */
    }

    100% {
        stroke-dasharray: 90, 150;
        /* 70%, 101% circumference */
        stroke-dashoffset: -124;
        /* -99% circumference */
    }
}

@-webkit-keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        /* 1%, 101% circumference */
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        /* 70%, 101% circumference */
        stroke-dashoffset: -35;
        /* 25% circumference */
    }

    100% {
        stroke-dasharray: 90, 150;
        /* 70%, 101% circumference */
        stroke-dashoffset: -124;
        /* -99% circumference */
    }
}

.chat__msg-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 12px;
    position: relative;
}

.chat__msg span:not(.chat__msg-footer) {
    width: 100%;
    font-size: 16px;
    word-spacing: 2px;
}

.blue_tag {
    width: auto !important;
}

.forwarded-icon span {
    font-size: 12px !important;
    white-space: nowrap;
}

.chat__msg span div {
    user-select: text;
    letter-spacing: 0em;
}

.chat__msg span {
    user-select: text;
    letter-spacing: 0em;
}

.chat__msg_hash span {
    user-select: text;
    letter-spacing: 0em;
}

.hash_area_prime_plus {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    background-color: #F0F0F0;
    width: -webkit-fill-available;
    margin-top: 5px;
}

.hash_area_prime_plus_icons {
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 5px;
}

.hash_area_prime_plus_icons svg {
    margin: 3px;
    width: 15px;
    height: 15px;
}

.hash_area_prime_plus_inner_title {
    color: #5260C6;
    font-size: 12px;
    font-family: SF-PRO-Rounded_light;
    line-height: 20px;
}

.hash_area_prime_plus_inner {
    padding: 5px 15px 5px 5px;
}

.hash_area_prime_plus_inner_data {
    color: #5260C6;
    font-size: 12px;
    word-break: break-all;
    font-family: SF-PRO-Rounded_light;
    line-height: 20px;
}

.hash_area_prime_plus_inner_data_error {
    color: #c65252;
    font-size: 12px;
}

.chat__msg {
    padding: 7px 7px 3px 7px;
    margin-bottom: 5px;
    font-size: 0.85rem;
    overflow-wrap: anywhere;
    flex-wrap: nowrap;
    color: #000000;
    width: fit-content;
    min-width: 100%;
    max-width: 90%;
    line-height: 20px;
    position: relative;
    white-space: pre-line;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    align-items: flex-end;
}

.chat__msg_hash {
    padding: 7px;
    margin-bottom: 5px;
    font-size: 0.85rem;
    overflow-wrap: anywhere;
    flex-wrap: wrap;
    color: #000000;
    width: auto;
    min-width: 100%;
    max-width: 90%;
    line-height: 20px;
    border-radius: 10px;
    position: relative;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    background-color: #FCFFFA;
    align-items: flex-end;
}

.chat__msg.chat__img-wrapper {
    padding: 4px;
    width: 95%;
}

.chat__msg--sent {
    background: #DBF8C6;
    align-self: flex-end;
}

.chat__msg--deleted {
    background: #ffe4e4 !important;
    align-self: flex-end;
}

.chat__msg_hash--sent {
    align-self: flex-end;
}

.chat__msg--rxd {
    background: white;
    align-self: flex-start;
}

.chat__msg_hash--rxd {
    align-self: flex-start;
}

.chat__msg-group>*:nth-child(1):not(.chat__msg--sent)::before,
.chat__msg--sent+.chat__msg--rxd::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: -8px;
    border-top: 6px solid white;
    border-right: 6px solid white;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
}

.chat__msg_hash-group>*:nth-child(1):not(.chat__msg_hash--sent)::before,
.chat__msg_hash--sent+.chat__msg_hash--rxd::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: -8px;
    border-top: 6px solid white;
    border-right: 6px solid white;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
}


.chat__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat__msg-filler {
    width: 65px;
    display: none;

    height: 3px;
    background: transparent;
}

.chat__msg-footer {
    position: relative;
    display: flex;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    font-weight: 500;
    z-index: 99;
    align-items: center;
    width: max-content;
    height: 20px;
    margin-top: 2px;
}

.chat__msg-status-icon {
    color: #B3B3B3;
    margin-left: 5px;
    max-width: 15px;
    max-height: 15px;
}

.chat__msg-status-icon--blue {
    color: #0DA9E5;
}

.chat__img-wrapper .chat__msg-footer,
.chat__img-wrapper .chat__msg-options-icon,
.chat__img-wrapper .chat__msg-status-icon {
    color: white;
}

.chat__msg-options {
    opacity: 0;
    position: absolute;
    right: 5px;
    z-index: 9999;
    top: 3px;
    pointer-events: none;
    transition: all 0.2s;
}

.chat__msg--rxd .chat__msg-options {
    background: transparent;
}

.chat__msg_hash--rxd .chat__msg-options {
    background: transparent;
}

.chat__msg--sent .chat__msg-options {
    background: transparent;
}

.chat__msg_hash--sent .chat__msg-options {
    background: transparent;
}

.chat__img-wrapper .chat__msg-options {
    background: transparent;
}

.chat__msg:hover .chat__msg-options {
    opacity: 1;
    pointer-events: unset;
}

.chat__msg-options-icon {
    color: rgb(145, 145, 145);
    width: 20px;
    height: 20px;
    background: transparent !important;
    z-index: 99999;
    top: 0px;
    position: absolute;
    right: 2px;
}

/* End Chat Content */

.chat__footer {
    background: rgb(240, 240, 240);
    position: relative;
}

.chat__scroll-btn {
    position: absolute;
    right: 15px;
    bottom: 95px;
    width: 42px;
    height: 42px;
    z-index: -1;
    border-radius: 50%;
    color: rgb(145, 145, 145);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);
    -webkit-animation-name: showUp;
    animation-name: showUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

/* Emoji Tray */

.emojis__wrapper {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 0;
    min-height: 0;
    transition: all 0.4s ease;
    background: inherit;
}

.emojis__wrapper--active {
    height: 40vh;
    min-height: 294px;
    transition: all 0.4s ease;
}

.emojis__tabs {
    display: flex;
    align-items: center;
    height: 50px;
}

.emojis__tab {
    flex: 1;
    padding: 10px 5px 10px;
    text-align: center;
    position: relative;
}

.emojis__tab--active::after {
    content: "";
    position: absolute;
    height: 4px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgb(0, 150, 136);
}

.emojis__tab-icon {
    color: rgba(0, 0, 0, 0.32);
}

.emojis__tab--active .emojis__tab-icon {
    color: rgba(0, 0, 0, 0.6);
}

.emojis__content {
    overflow-y: scroll;
    padding: 5px 20px;
    flex: 1;
}

.emojis__search {
    height: 40px;
    background: #E6E6E6;
    width: 100%;
    border-radius: 5px;
    padding: 5px 10px;
    color: rgb(74, 74, 74);
    font-size: 0.9rem;
}

.emojis__search::placeholder {
    color: #989898;
}

.emojis__label {
    margin-top: 15px;
    margin-bottom: 5px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.85rem;
}

.emojis__grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.emojis__emoji {
    margin-right: 3px;
    margin-top: 3px;
    cursor: pointer;
}

/* End Emoji Tray */

/* Chat Footer Toolbar */
.wide-view .chat__input-wrapper {
    border-radius: 0px 0px 0px 10px;
}

.chat__input-wrapper {
    padding: 10px;
    min-height: 60px;
    position: relative;
    display: flex;
    height: auto;
    align-items: center;
    transition: all 0.4s ease;
    border-radius: 0px 0px 10px 10px;
}

.chat__input-icon {
    color: #919191;
    margin-left: 8px;
    margin-right: 8px;
    width: 28px;
    height: 28px;
    padding: 3px;
    border-radius: 50%;
}

.chat__input-icon--highlight {
    color: teal;
}

.chat__attach {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 50px;

}

.chat__attach-btn {
    transform: scale(0);
    opacity: 0;
    transition: all 0.5s ease;
}

.chat__attach-btn:nth-of-type(1) {
    transition-delay: 0.5s;
}

.chat__attach-btn:nth-of-type(2) {
    transition-delay: 0.4s;
}

.chat__attach-btn:nth-of-type(3) {
    transition-delay: 0.3s;
}

.chat__attach-btn:nth-of-type(4) {
    transition-delay: 0.2s;
}

.chat__attach-btn:nth-of-type(5) {
    transition-delay: 0.1s;
}

.chat__attach--active .chat__attach-btn {
    transform: scale(1);
    opacity: 1;
}

.chat__attach-btn {
    margin-bottom: 10px;
}

.chat__input-icon--pressed {
    background: rgba(0, 0, 0, 0.1);
}

.chat__input {
    background: white;
    color: rgb(74, 74, 74);
    padding-top: 17px !important;
    padding: 10px 10px;
    font-size: 15px;
    line-height: 1.4;
    min-height: 28px;
    height: 56px;
    overflow-y: scroll;
    display: flex;
    border-radius: 22px;
    flex: 1 1;
    max-height: 200px;
    text-transform: none !important;
}

.chat__input:focus {
    outline: none;
}

input:focus {
    border: none;
    outline: none;
}

autoheight-textarea {
    display: flex;
    /* width: -webkit-fill-available; */
    flex: 1;
}

.chat__input::-webkit-scrollbar-thumb {
    background-color: #DDDDDD;
    border-radius: 5px;
}

.chat__input::-webkit-scrollbar {
    width: 8px;

}

.chat__input::placeholder {
    color: rgb(153, 153, 153);
    font-size: 0.9rem;
}

.chat__input::-webkit-scrollbar {
    display: none;
}

/* End Chat Footer Toolbar */

/* Chat Sidebar */
.back_icon {
    margin: 0px;
    margin-right: 5px;
    margin-left: 6px;
}

.wide-view .cht-hed {
    border-radius: 10px 0px 0px 0px;
}

.cht-hed {
    padding-left: 0px !important;
    border-radius: 10px 10px 0px 0px;
}

.wide-view .chat-sidebar {
    left: -1800px;
    right: initial !important;
}

.wide-view .chat-sidebar--active {
    left: 0px;
    width: 100%;
    z-index: 999;
}

.chat-sidebar {
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    z-index: 10;
    overflow: hidden;
    position: absolute;

    right: -1800px;
    border-radius: 10px;
    display: none;
    flex-direction: column;
    transition: all 0.4s ease;
    overflow-x: hidden;
    overflow-y: auto;
}

.chat-sidebar--active {
    right: 0px;
    width: 100%;
    display: flex !important;
    z-index: 999;
}

.chat-sidebar--statics {
    min-width: 450px;
    overflow: hidden;
    height: 100%;
    z-index: 99999999;
}

.dark-theme .chat-sidebar--statics .chat-sidebar__content {
    background: #131c21;
}

.chat-sidebar--statics .chat-sidebar__content {
    height: -webkit-fill-available;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 60px);
    background: #F7F7F7;
    padding-top: 0px;
}

.chat-sidebar--statics .chat-sidebar__content::-webkit-scrollbar-thumb {
    background-color: #a29c9c;
}

.dark-theme .chat-sidebar--statics .header .chat-sidebar__heading {
    color: #f1f1f2eb;
}

.dark-theme .chat-sidebar--statics .header {
    border-bottom: 0.5px solid #2a2f32;
    background-color: #2a2f32;
}

.chat-sidebar--statics .header {
    border-bottom: 0.5px solid #dddddd;
    background-color: #FAFAFA;
}

.chat-sidebar--statics .header .chat-sidebar__heading {
    color: #5D5C5D;
    font-size: 16px;
    font-weight: 600;
}

.chat-sidebar__header-icon {
    margin-right: 20px;
    color: rgb(145, 145, 145);
    display: flex;
}

.chat-sidebar__heading {
    flex: 1;
    color: #000000;
    font-size: 1rem;
    margin-bottom: 2px;
}

.chat-sidebar__content {
    flex: 1 1;
    overflow: auto;
    padding-top: 5px;
}

.chat-sidebar__search-results {
    background: white;
    height: 100%;
    padding-top: 2pc;
    color: #00000099;
    text-align: center;
    font-size: 0.85rem;
}

.profile {
    background: rgb(237, 237, 237);
    padding-bottom: 2pc;
}

.dark-theme .statics {
    background-color: #131c21;
}

.statics {
    /* background: rgb(237, 237, 237); */
    display: flex;
    flex-direction: column;
    align-items: center;
}

@keyframes slideBottom {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.ticket_animate_new_elem {
    animation-name: slideBottom;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

.dark-theme .ticket_open_close {
    background-color: #2a2f32;
}

.status10001 {
    background-color: #F0FFF5
}

.status10002 {
    background-color: #F0F4FF
}

.status10003 {
    background-color: #FDEAEA
}

.status10004 {
    background-color: #ffda8d2e
}

.status10005 {
    background-color: #FAFAFA
}

.ticket_open_close {
    width: -webkit-fill-available;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    border-radius: 10px;
    /* overflow: hidden; */
}

.ticket_open_close_data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;
    margin: 10px;
}

.ticket_open_close_title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
    margin: 10px;
    cursor: pointer;
}

@keyframes slideBottomRef {
    0% {
        transform: translateY(-40px);
        opacity: 0;
        color: #09DE00;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
        color: #09DE00;
    }
}

.ticket_open_close_title_element_animate {
    animation-name: slideBottomRef;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

/* #FF9429 */
.dark-theme .ticket_open_close_title .ticket_open_close_title_element:not(:last-child) {
    color: #F0F0F0;
}

.ticket_open_close_title .ticket_open_close_title_element:not(:last-child) {
    width: -webkit-fill-available;
    font-size: 14px;
    color: #5D5D5D;
    font-family: SF-PRO-Rounded_light;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
}

.ticket_open_close_title .ticket_open_close_title_element:not(:last-child) svg {
    margin-right: 3px
}

.dark-theme .ticket_open_close_title .ticket_open_close_title_element:last-child {
    color: #F0F0F0;
}

.ticket_open_close_title .ticket_open_close_title_element:last-child {
    min-width: 90px;
    color: #5D5D5D;
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    font-family: SF-Pro-Rounded;
}

.profile__section {
    background: white;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
    padding: 10px 20px;
}

.profile__section--personal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
}

.profile__avatar-wrapper {
    width: 200px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 180px;
}

.profile__name {
    flex: 1;
    color: #000000;
    font-size: 1.2rem;

}

.contact-detail .profile__name {
    width: fit-content;
    text-align: center;
}

.profile__heading-wrapper {
    margin-top: 5px;
    margin-bottom: 10px;
}

.profile__heading {
    color: rgb(0, 150, 136);
    font-size: 0.85rem;
    flex: 1;
}

.profile__heading-icon {
    color: rgb(145, 145, 145);
}

.profile__media-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile__media {
    width: 32%;
}

.profile__action,
.profile__about-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    margin-bottom: 5px;
    cursor: pointer;
}

.profile__action:not(:last-of-type),
.profile__about-item:not(:last-of-type),
.profile__group:not(:last-of-type) {
    border-bottom: 1px solid #EBEBEB;
}

.profile__action-left {
    flex: 1;
}

.profile__action-text {
    display: block;
}

.profile__action-text--top,
.profile__about-item {
    font-weight: 500;
    margin-bottom: 5px;
}

.profile__action-text--bottom {
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.45);
}

.profile__section--groups {
    padding-left: 0;
    padding-right: 0;
}

.profile__group,
.profile__group-heading {
    padding-left: 20px;
    padding-right: 20px;
}

.profile__group {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.profile__group:hover {
    background-color: #EBEBEB;
}

.profile__group-content {
    flex: 1;
    overflow: hidden;
}

.profile__group-avatar-wrapper {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.profile__group-text {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.profile__group-text--top {
    color: #000000;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 5px;
}

.profile__group-text--bottom {
    color: #00000099;
    font-size: 0.85rem;
    overflow: hidden;
}

.profile__section--danger {
    color: rgb(223, 51, 51);
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.profile__danger-icon {
    margin-right: 20px;
}

.profile__danger-text {
    flex: 1;
}

/* End  Chat Sidebar */

@media screen and (min-width: 1301px) {}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
    .chat__msg {
        max-width: 346px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
    .chat__msg {
        max-width: 85%;
    }
}

@media screen and (max-width: 1000px) {
    .chat-sidebar {


        width: 100%;
        height: 100%;
        z-index: 10;
    }

}

@media screen and (min-width: 750px) {
    .chat__msg.chat__img-wrapper {
        width: 40%;
        min-width: 300px;
        max-width: 400px;
    }
}

.img-mes {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    object-fit: contain;
    height: 17vh;
}

.dark-theme .image_show {
    box-shadow: inset 0px -10px 5px 5px #0000002e;
}

.image_show {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    object-fit: contain;
    height: 17vh;
    box-shadow: inset 0px -10px 5px 5px #ffffff2e;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

.infinite-scroll-component {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.group_name {
    color: #06cf9c !important;
    font-size: 12.8px;
    width: 100%;
    min-height: 22px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.from_orginal_span {
    color: #809a94 !important;
    font-size: 10.8px !important;
    min-height: 22px;
    margin-left: 5px;
}

.group_ava {
    position: absolute;
    left: -20px;
    top: 0px !important;
    display: flex;
    border-radius: 50%;
    width: 20px;
    height: 20px;

}

.group_widg {
    padding-left: 5px !important;
}

.group_chat_msg {
    min-height: 43px;
    flex-wrap: wrap;

}

.chat__msg--rxd .group_ava {
    left: -25px !important;
}

.chat__msg_hash--rxd .group_ava {
    left: -25px !important;
}

.chat__msg-group .group_ava {
    display: flex;
}

.chat__msg--sent .group_ava {
    width: 20px !important;
    height: 20px !important;
    top: -3px;
    right: -23px !important;
    left: inherit !important;
}

.chat__msg_hash--sent .group_ava {
    width: 20px !important;
    height: 20px !important;
    top: -3px;
    right: -23px !important;
    left: inherit !important;
}

.image_prev {
    position: relative;
    width: 100%;
    top: 0px;
    right: 0px;
    z-index: 99999;
    min-width: 423px;
    height: calc(100% - 50px);
    background-color: #e9edef;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    display: flex;
    position: relative;
    transition: all 0.4s ease;
    position: absolute;
    height: 100%;

}

.image_prev img {
    max-width: 80%;
    height: 70%;
    border-radius: 10px;

}

.send_button_img {
    background-color: #00a884;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MessageCaption {
    width: 100%;
    /* padding-left: 20px; */
    position: absolute;
    bottom: 18px;
    /* padding-right: 20px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.MessageCaption input {
    height: 50px;
    max-width: 86%;
}

.send_button_img svg path {
    fill: #fff
}

.sppin {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    z-index: 99999;
}

.sppin svg {
    transform: scale(1.8)
}

.fixed-img-prev {
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: transparent;
    height: 100vh;
    display: flex;
    z-index: 9999999999;
    align-items: center;
    justify-content: center;
}

.fixed-img-prev .svv {
    position: absolute;
    top: 29px;
    right: 30px;
    cursor: pointer;
    z-index: 99999;


}

.fixed-img-prev .bac-drop {
    background-color: #585751;
    opacity: 0.6;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px
}

.fixed-img-prev img,
.fixed-img-prev video {
    width: auto;
    max-width: 100%;
    z-index: 999999999;
    height: auto;
    max-height: 100%;
}

.recorder-cont {
    position: absolute;
    bottom: -77px;
    padding-top: 10px !important;
    z-index: 99999;
    height: 90px;
    width: 100%;
    padding: 15px;
    display: flex;
    background-color: #ededed;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.recorder-row1 {
    width: 100%;
    height: auto;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.recorder-duration {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recorder-status {
    display: flex;
    width: 66%;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.4s ease;
    opacity: 1;
}

.recorder-tex {
    margin-left: 0px;

}

.del-icon-wa {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.3%;
}

.last-wa {
    justify-content: flex-end;
}

.first-wa {
    justify-content: flex-start;
    padding-left: 10px;
}

.ml-0 {
    margin-left: 0px !important;
}

.chat__msg-options {

    opacity: 0;
    width: auto;
    height: auto;
    position: absolute;
    right: 5px;
    z-index: 9999999999;
    top: 3px;
    pointer-events: none;
    transition: all 0.2s;
    min-width: 30px;
    min-height: 30px;
}

.options-btn__options {
    z-index: 1000;
    top: 27px !important;

    position: absolute;
    min-width: 200px;
    padding: 10px 0;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 #00000042, 0 2px 10px 0 rgb(0 0 0 / 16%);
    transition: all 0.2s ease-in;

}

.lf {
    left: 0px !important;
}

.ri {
    right: 0px !important;
}

.options-btn__option {
    display: flex;
    justify-content: flex-start;
}

.wide-view .chat__footer {
    border-radius: 0px 0px 0px 10px;
}

.chat__footer {
    z-index: 999;
    border-radius: 0px 0px 10px 10px;
}

.replay-msg {
    border-radius: 7.5px;
    max-width: 100%;
    position: relative;
    width: 100%;
    height: 54px;
    background-color: rgba(70, 59, 59, .10980392156862745);
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.bar-c {
    border-top-left-radius: 7.5px;
    border-bottom-left-radius: 7.5px;
    flex: none;
    height: 100%;
    width: 4px;
    background-color: #06cf9c !important;
}

.reply-cont {
    padding: 4px 12px 8px 8px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    align-items: flex-start;
    width: 100%;
    max-width: 450px;
    flex-direction: column;
    justify-content: space-evenly;
}

.quotedImg {
    width: 58px;
    height: 100%;
}

* {
    scroll-behavior: auto;
}

.reply-name.group_name {
    color: #06cf9c;
    position: static !important;
    top: 21px;
}

.reply-desc img,
.reply-desc svg {
    margin-right: 4px;
}

.reply-desc {
    color: #667781;
    font-size: 14px !important;
    word-spacing: 0px;
    line-break: anywhere;
    font-size: 14px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #667781;
    display: flex;

    justify-content: flex-start;
    /* width: 34px; */
    max-width: 100%;
    line-break: anywhere;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    padding-left: 4px;
    text-overflow: ellipsis;
    align-items: center;
}

.reply-desc svg {
    margin: 2px;
}

.reply-sc {
    width: 100%;
    position: relative;
    border-radius: 7.5px;
    height: 88px;
    background-color: #f0f2f5;
    padding-top: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.replay-mes {
    width: 100%;
    max-width: 346px;
    display: flex;
    align-items: center;
    height: 83px;
    flex-direction: row;
    justify-content: center;
}

.reply-container {
    display: flex;
    justify-content: space-evenly;
    padding-left: 5px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
}

.replay-img {
    width: 83px;
    height: 83px;
    border-radius: 7px;

}

.replay-img img {
    width: 83px;
    height: 83px;
    border-radius: 7px;
}

.reply-cancel {
    position: absolute;
    top: 39px;
    color: #667781;
    right: 20px;
}

.reply-cancel path {
    fill: #667781;

}

.sticker_msg img {
    max-width: 190px !important;
}

.active-chat-user {
    cursor: grab;

}

.chat-user-item,
.chat-user-item * {
    user-select: none;
}

.sticker_msg {}

.chat__file {
    height: auto;
    /* justify-content: space-between; */
    width: 283px;
}

/* @keyframes slideInSent {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes slideInRxd {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

.message-hold--rxd {
    animation-name: slideInRxd;
    animation-duration: 0.77s;
    animation-iteration-count: 1;
} */

.message-hold--sent {
    align-self: flex-end;
    /* animation-name: slideInSent;
    animation-duration: 0.77s;
    animation-iteration-count: 1; */
}

.message-hold * {
    text-transform: none !important;
}

.message-hold {
    width: fit-content;
    display: flex;
    min-width: 100px;
    max-width: 90%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 10px;
    position: relative;
}

.name_account_ammount {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dark-theme .name_account_ammount .inner_item {
    color: #F0F0F0;
}

.name_account_ammount .inner_item {
    font-size: 14px;
    color: #8E8E8E;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.name_account_ammount .inner_item_error {
    font-size: 12px;
    color: #e05858;
}

.hash_sender_name {
    padding-bottom: 8px;
    color: #4A71F2;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hash_sender_name svg {
    margin-left: 6px;
    margin-right: 15px;
}

.name_account_ammount .inner_item svg {
    margin-left: 6px;
    margin-right: 15px;
}

.message-hold--sent .abs-menu {
    align-self: flex-end;
}

.message-hold::before {
    display: none !important;
}

p+.abs-menu .reply-but,
p .abs-menu .message-ops {
    background-color: #fff !important;
}

.message-container .message-hold {
    flex-direction: column-reverse !important;
}

.message-container .message-hold .abs-menu {
    padding-top: 5px !important;
}

.newed {

    color: #0DA9E5;
    font-size: 20px;
    margin: 0px;
    display: flex;
    height: 30px;
    margin-right: 5px;
    align-items: center;
    justify-content: center;

}

.forwarded-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.forwarded-icon svg path {
    fill: #c1bfb9;
    height: 12px;
}

.forwarded-icon svg {
    max-width: 15px;
    max-height: 15px;
}

.reply-desc svg {
    max-width: 20px;
    max-height: 20px;
}

.forwarded-icon span {
    display: flex;
    height: 17px;
    margin-left: 3px;
    color: #54656f;
    font-size: 11px;
    font-family: "SF-PRO-Rounded_light";
    align-items: flex-end;
}

.padTop {
    height: auto;
    min-width: 177px;
    padding-top: 44px !important;
}

.blue_tag {
    color: #53bdeb;
}

.padTop .group_name {
    top: 19px !important;
    top: 19px !important;
    position: absolute;
}

.vid-cont {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    opacity: 0;
    background-color: transparent;
}

p:hover .vid-cont,
.vixs {
    opacity: 1 !important;
}

.vid-cont svg {
    transform: scale(2.5);
}

.contact-edit-it {
    min-height: 80px !important;
}

.member-menu {
    width: 200px;
    height: 300px;
    overflow-y: scroll;
    position: absolute;
    bottom: 80px;
    left: 100px;
    background-color: #f7f7f7;
    border-radius: 10px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start
}

.member-menu::-webkit-scrollbar-thumb {
    background-color: #706666;
    border-radius: 5px;
}


.member-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    width: 100%;
    cursor: pointer;
}

.member-item:hover {
    background-color: #e2e1e1;
}

.member-item img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.member_name {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: 10px;

    color: #585751;
}


.row-aud {
    display: flex;
    align-items: center;
    margin-top: 5px;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.top-abs-ch {
    height: 50%;
    width: 100%;
    background-color: rgba(255, 0, 0, 0.274);
    z-index: 400;
}

.vcard {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    min-width: 200px;
}

.vcardFromMe {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    min-width: 200px;
    flex-wrap: nowrap;
}

.vcardFromMe .ava-vcard {
    width: 49px;
    height: 49px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px;
}

.vcardFromMe span {
    font-family: "SF-PRO-Rounded";
    font-size: 14px;
}

.vcard .ava-vcard {
    width: 49px;
    height: 49px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px;
}

.vcard span {
    font-family: "SF-PRO-Rounded";
    font-size: 14px;
}

.dark-theme .vcard-option {
    border-top: 1px solid rgba(227, 234, 238, 0.06);
}

.vcard-option {
    width: 100%;
    flex: 1;
    height: 36px;
    color: #027eb5;
    font-size: 14px;
    font-family: "SF-PRO-Rounded_light";
    padding: 10px;
    display: flex;
    border-top: 1px solid rgba(17, 27, 33, 0.06);
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.contact-comps {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.contact-comps select {
    margin-top: 5px;
}

path#svg-camera {
    transform: translate(15px, 15px) !important;
}

@keyframes color-change-2x {
    0% {
        background-color: #F0FFF5;
        border-color: #09DE00;
    }

    100% {
        background-color: #FDEAEA;
        border-color: #FF7171;
    }
}

@keyframes color-change-2x-dark {
    0% {
        background-color: #2a2f32;
        border-color: #09DE00;
    }

    100% {
        background-color: #2a2f32;
        border-color: #FF7171;
    }
}

.dark-theme .ticket_open_close_wait_holder {
    height: 40px;
    width: -webkit-fill-available;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
    border-radius: 10px;
    border: 0.5px solid #09DE00;
    -webkit-animation-name: color-change-2x-dark;
    animation-name: color-change-2x-dark;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ticket_open_close_wait_holder {
    height: 40px;
    width: -webkit-fill-available;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
    border-radius: 10px;
    border: 0.5px solid #09DE00;
    animation-name: color-change-2x;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-timing-function: linear;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dot-floating-white {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    top: -10px;
    background-color: #FAFAFA;
    color: #FAFAFA;
    animation-name: dot-floating-white;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.15, 0.6, 0.9, 0.1);
    animation-fill-mode: both;
}

@keyframes dot-floating-white {
    0% {
        left: calc(-50% - 5px);
    }

    75% {
        left: calc(50% + 25px);
    }

    100% {
        left: calc(50% + 25px);
    }
}

.dark-theme .dot-floating {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    top: 10px;
    background-color: #FAFAFA;
    color: #FAFAFA;
    animation-name: dot-floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.15, 0.6, 0.9, 0.1);
    animation-fill-mode: both;
}

.dot-floating {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    top: 10px;
    background-color: #404040;
    color: #404040;
    animation-name: dot-floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.15, 0.6, 0.9, 0.1);
    animation-fill-mode: both;
}

@keyframes dot-floating {
    0% {
        left: -10px
    }

    75% {
        left: 15px;
    }

    100% {
        left: 25px;
    }
}

.forward_image_preview {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.forward_image_preview_text {
    width: -webkit-fill-available;
    height: 66px;
    background-color: #FAFAFA;
    border-left: 12px solid #BFFFCB;
    margin-left: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    color: #00000099;
    font-size: 1rem;
    line-break: strict;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    line-height: 20px;
    overflow: auto;
}

.forward_image_preview_text svg {
    margin-right: 5px;
}

.forward_image_preview_text button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 25px;
    height: 25px;
}

.forward_image_preview_image img {
    width: 66px;
    height: 66px;
    border-radius: 10px;
    background-color: #FAFAFA;
    object-fit: fill;
}

.forward_image_preview_image video {
    width: 66px;
    height: 66px;
    border-radius: 10px;
    background-color: #FAFAFA;
    object-fit: fill;
}

.forward_image_preview_image {
    width: 66px;
    height: 66px;
    border-radius: 10px;
}